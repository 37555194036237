<script lang='ts'>
  import { onDestroy } from 'svelte'

  import { languages } from '@/config/languages'

  import { removeEmpty, uniqueLangs } from '@/helpers/forumHelpers'
  import { _ } from '@/helpers/i18n'
  import { clickOutside, toInt } from '@/helpers/mixHelpers'

  import Flag from '@/components/forum/Flag.svelte'
  import LanguageChooser from '@/components/forum/LanguageChooser.svelte'
  import Icon from '@/components/icons/Icon.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'

  import { LanguageGroups } from '@/definitions/langoid'
  import { forumConfig, userStorredConfig } from '@/store'

  let editKnownLanguages = false
  let knownText = ''
  let learningText: any = []

  const DELAY_TO_FIX_CHROME_BUG = 500
  const languageGroups: LanguageGroups = {
    known: [],
    learn: []
  }

  const shownLanguages = (type: 'known' | 'learn') => {
    if (!languageGroups[type]) return ''
    if (languageGroups[type].length === 0) {
      if (type === 'learn') return 0
      return $userStorredConfig.id_lang_interface || 1
    }
    return languageGroups[type].map((el) => {
      const lang = toInt(el.lang)
      const found = languages.find(l => l.id === lang)
      return found?.id || undefined
    }).filter(el => el)
  }

  const unsubscribe = forumConfig.subscribe((config: LanguageGroups) => {
    languageGroups.known = config.known
    languageGroups.learn = config.learn
    learningText = shownLanguages('learn')
  })

  const unsubscribe2 = userStorredConfig.subscribe(config => {
    if (config.id_lang_interface) {
      if (languageGroups.known?.length === 0) {
        languageGroups.known.push({ lang: config.id_lang_interface })
        forumConfig.set(languageGroups) // on first load
      }
      knownText = shownLanguages('known')
    }
  })

  const setInterfaceLanguages = (langs: any) => {
    languageGroups.known = uniqueLangs(removeEmpty(langs))
    forumConfig.set(languageGroups)
    editKnownLanguages = false
    knownText = shownLanguages('known')
  }

  const setLearningLanguages = (langs: any) => {
    languageGroups.learn = uniqueLangs(removeEmpty(langs))
    forumConfig.set(languageGroups)
    editKnownLanguages = false
    learningText = shownLanguages('learn')
  }

  function onLanguageChooserSave (event: CustomEvent) {
    // event.detail will hold your saved data
    const { languages, type } = event.detail

    if (type === 'known') {
      setInterfaceLanguages(languages)
    } else {
      setLearningLanguages(languages)
    }
  }

  setTimeout(() => {
    if ($forumConfig.known.length === 0) {
      // Important: this fixes chrome bug with filters
    }
  }, DELAY_TO_FIX_CHROME_BUG)

  onDestroy(() => {
    unsubscribe()
    unsubscribe2()
  })
</script>

<div class='languages-filter _row'>
  <div class='selected-languages-on-topics'>
    {#each knownText as langId}
      {#key langId}
        <Flag id={toInt(langId)} />
      {/key}
    {/each}
  </div>
  {#if Object.values(learningText || []).length}
    <span class='verticalLine' />
    <div class='selected-languages-about-topics'>
      {#each Object.values(learningText || []) as langId}
        {#key langId}
          <Flag id={langId} />
        {/key}
      {/each}
    </div>
  {/if}
  <span class='verticalLine' />
  <FakeButton onClick={() => { editKnownLanguages = !editKnownLanguages }}>
    <span class='sliders'><Icon icon='Sliders' weight='regular' /></span>
  </FakeButton>
  {#if editKnownLanguages}
    <div class='fake-overlay' />
    <div class='popup-languages-chooser' on:click_outside={() => {editKnownLanguages = false}} use:clickOutside>
      <div class='chooser-header'>
        <h3 class='heading'>{$_('forum.showTopicsIn')}</h3>
        <FakeButton onClick={() => {editKnownLanguages = false}}>
          <Icon icon='X' weight='thin' />
        </FakeButton>
      </div>
      <LanguageChooser
        languages={languageGroups.known}
        type='known'
        on:save={onLanguageChooserSave}
      />
      <h3 class='heading'>{$_('forum.topicsAbout')}</h3>
      <LanguageChooser
        canBeEmpty
        languages={languageGroups.learn}
        type='learning'
        on:save={onLanguageChooserSave}
      />
    </div>
  {/if}
</div>
<style lang='scss'>
  .languages-filter {
    position: relative;
    width: fit-content;
    height: 4rem;
    padding: 0.8rem;
    background-color: var(--main-background);
    border: 0.1rem solid var(--main-border);
    border-radius: 0.8rem;

    > .verticalLine {
      height: 2.4rem;
      border-right: 0.1rem solid var(--Gray-Light);
    }

    > :global(.fake-button .sliders) {
      height: 2.4rem;
      color: var(--text-primary-color);
    }
  }

  .selected-languages-on-topics, .selected-languages-about-topics {
    display: flex;
    gap: 0.4rem;
  }

  .popup-languages-chooser {
    position: absolute;
    top: 4rem;
    right: 0;
    z-index: 10;
    width: fit-content;
    background-color: var(--main-background);
    border: 0.1rem solid var(--Gray-Light);
    border-radius: 1.2rem;
    box-shadow: var(--Shadow-Y);

    > .heading {
      padding: 1.2rem 1.6rem;
      font: var(--Medium-400);
      border-top: 0.1rem solid var(--Gray-Light);
      border-bottom: 0.1rem solid var(--Gray-Light);
    }
  }

  .chooser-header {
    display: flex;
    gap: 1.6rem;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem 1.6rem;
    border-bottom: 0.1rem solid var(--Gray-Light);

    > .heading {
      font: var(--Medium-400);
      white-space: nowrap;
    }
  }

  .fake-overlay {
    display: none;
  }

  @media (max-width: 768px) {
    .languages-filter {
      position: static;
      padding: 0.4rem;

      > .verticalLine {
        display: none;
      }
    }

    .selected-languages-on-topics, .selected-languages-about-topics {
      display: none;
    }

    .fake-overlay {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 90;
      width: 100%;
      height: 100vh;
      background-color: rgba(99, 99, 99, 0.5);
      content: '';
    }

    .popup-languages-chooser {
      position: fixed;
      top: initial;
      bottom: 0;
      z-index: 90;
      width: 100%;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
</style>
