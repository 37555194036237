<script lang='ts'>
  import { onDestroy } from 'svelte'
  import { link, navigate } from 'svelte-routing'

  import { MAX_LINK_LENGTH } from '@/config/constants'

  import { getCookie, getIsoFromId } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { isAllowed, mapTranslations, removeEmpty, reorderLanguages } from '@/helpers/forumHelpers'
  import { _ } from '@/helpers/i18n'
  import { slugify, toInt, truncateUrl } from '@/helpers/mixHelpers'

  import Flag from '@/components/forum/Flag.svelte'
  import FlagsList from '@/components/forum/FlagsList.svelte'
  import ForumTabFilters from '@/components/forum/ForumTabFilters.svelte'
  import LanguagesFilter from '@/components/forum/LanguagesFilter.svelte'
  import IconLocked from '@/components/icons/IconLocked.svelte'
  import IconTopic from '@/components/icons/IconTopic.svelte'
  import UserAvatar from '@/components/social/UserAvatar.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import Pagination from '@/components/ui/Pagination.svelte'

  import {
    CategoryData,
    CategoryItem,
    CategoryTopic,
    ForumLanguage,
    LanguageGroups,
    UserAvatarData,
    UserData
  } from '@/definitions/langoid'
  import { forumConfig, userStorredConfig } from '@/store'

  export let category = 0,
    iso = 'eng',
    page: number = 1

  let myTranslations: Record<number, any> = {}

  let user: UserData
  $ : user = $userStorredConfig
  let topics: CategoryTopic[] = [],
    currentCategory: CategoryItem = { title: '' } as CategoryItem,
    currentCategoryGroup: string,
    translations: Record<number, string> = {},
    myLanguages: number[] = [],
    lastPage: number,
    users: Record<number, UserAvatarData>,
    breads: any = []

  const unsubscribe = userStorredConfig.subscribe(user => {
    const tmp = getIsoFromId(user.id_lang_learning, true)
    iso = tmp || 'eng'
  })

  const loadData = (data: CategoryData) => {
    myTranslations = data.myTranslations
    topics = data.topics.map(el => mapTranslations(el, data.myTranslations))
    translations = data.translations
    currentCategory = data.category
    currentCategoryGroup = data.categoryGroup
    users = data.users
    if (Object.keys(data.categoryTranslation).length) {
      currentCategory.title = data.categoryTranslation.title_translation
      currentCategory.content = data.categoryTranslation.translation
    }
    lastPage = Number(data?.lastPage) || 1
    breads = [
      { mobileUrl: `/${iso}/forum/categories`, text: '', url: `/${iso}` },
      { text: 'forum.categories', url: `/${iso}/forum/categories` },
      { text: currentCategory.title }
    ]
  }

  const jwt = getCookie('jwt')
  let unsubscribe2: () => void
  $: if (jwt && category) {
    unsubscribe2 = forumConfig.subscribe((config: LanguageGroups) => {
      if (config.known.length) {
        loadCategory(category, config, iso, page)
      }
    })
  } else {
    loadCategory(category, { known: [], learn: [] }, iso, page)
  }

  async function loadCategory (categoryId: number, config: any, iso: string, page: number) {
    const data = await fetchData('forum/loadCategory', { categoryId, config, iso, page })
    loadData(data)
    const toMap: ForumLanguage[] = config.known.length ? config.known : data.known
    myLanguages = toMap.map(el => toInt(el.lang))
  }

  const clickOnFlag = async (language: number, topicId: number) => {
    const data = await fetchData('forum/translateSingleItem', { language, replyId: 0, topicId })
    topics = topics.map((topic) => {
      if (topic.id === topicId) {
        topic.content = data.content
        topic.currentLanguage = language
      }
      return topic
    })
  }
  const pageChanged = async (e: CustomEvent) => {
    const config = $forumConfig
    page = toInt(e.detail)
    const data = await fetchData('forum/loadCategory', { categoryId: category, config, iso, page })
    navigate(`/${iso}/c/${slugify(currentCategory.title)}/${category}${page > 1 ? '/' + page : ''}`)
    loadData(data)
    myLanguages = config.known.map(el => toInt(el.lang))
  }
  let learn
  $: ({ learn } = $forumConfig)
  learn = removeEmpty(learn)

  onDestroy(() => {
    unsubscribe()
    if (unsubscribe2) unsubscribe2()
  })
</script>
{#key category}
  <div class='category-page-wrapper'>
    <div class='category-page-content _vertical-small'>
      <Breadcrumb {breads} filters>
        {#if user.id}
          <ForumTabFilters
            {currentCategory}
            {currentCategoryGroup}
            group='categories'
            {iso}
            {myTranslations}
            {topics}
          />
        {/if}
        <div slot='filters' class='_breadcrumbicons'>
          {#if user.id && jwt}
            <LanguagesFilter />
          {/if}
        </div>
      </Breadcrumb>
      {#if user.id}
        <div class='tabs-and-filters'>
          <ForumTabFilters
            {currentCategory}
            {currentCategoryGroup}
            group='categories'
            {iso}
            {myTranslations}
            {topics}
          />
          {#if jwt}
            <LanguagesFilter />
          {/if}
        </div>
      {/if}
      <div class='all-topics _gap24'>
        {#if topics.length}
          {#each topics as topic (topic.id)}
            {@const permissions = isAllowed(topic, $userStorredConfig)}
            <div class='single-topic'>
              <div class='header-content'>
                <h5>{currentCategoryGroup} / {currentCategory.title}</h5>
                <span>•</span>
                <div class='_row'>
                  <FlagsList
                    active={topic.currentLanguage}
                    clickOnFlag={(lang) => { clickOnFlag(lang, topic.id) }}
                    languages={reorderLanguages(translations[topic.id] ? translations[topic.id].toString().split(',').map(Number) : [], myLanguages)}
                    {topic}
                  />
                  {#if translations[topic.id]?.toString().split(',').map(Number)}
                    •
                  {/if}
                  <Flag
                    id={topic.id_language}
                    active={topic.currentLanguage === topic.id_language}
                    clickOnFlag={(lang) => { clickOnFlag(topic.id_language, topic.id) }}
                  />
                </div>
              </div>
              <div
                class='topic-content'
                role='button'
                tabindex='0'
                on:click={() => navigate(`/${iso}/forum/${category}/${topic.id}`)}
                on:keypress={() => {}}
              >
                <div class='topic-permition' class:-hide={!topic.target_language && !topic.is_locked}>
                  {#if topic.target_language}
                    <Flag id={topic.target_language} variant='small' />
                  {/if}
                  {#if permissions[0]}
                    {#if topic.is_locked}
                      <span class='smallIcons'>
                        <IconLocked {topic} />
                      </span>
                    {/if}
                  {/if}
                </div>
                {#if permissions[0]}
                  <a class='title' href={`/${iso}/t/${slugify(topic.content)}/${topic.id}`} use:link>
                    <IconTopic type={topic.type} /> {truncateUrl(topic.content, MAX_LINK_LENGTH)}</a>
                {:else}
                  <h4 class='title'>
                    <IconLocked fill='red' {topic} />
                    <IconTopic type={topic.type} /> {truncateUrl(topic.content, MAX_LINK_LENGTH)}</h4>
                {/if}
              </div>
              <div class='_row'>
                <UserAvatar
                  avatarVariant='smallAvatar'
                  date={topic.created_at}
                  dateVariant='withDate'
                  metaDataVariant='right'
                  user={users[topic.id_user]}
                />
                <span>•</span>
                <div class='replies-number'>
                  <span>{topic.num_of_replies}</span>
                  <span>{$_('forum.replies')}</span>
                </div>
              </div>
            </div>
            <hr />
          {/each}
        {:else}
          <h3>
            {#if learn?.length}
              {$_('forum.noFilteredTopics')}
            {:else}
              {$_('forum.noTopics')}
            {/if}
          </h3>
        {/if}
      </div>
      {#if lastPage > 1 && page > 0}
        <Pagination count={lastPage} current={Number(page)} on:change={pageChanged} />
      {/if}
    </div>
  </div>
{/key}

<style lang='scss'>
  .category-page-wrapper {
    display: flex;
    gap: 1.6rem;
  }

  .category-page-content {
    width: 100%;
  }

  .tabs-and-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .all-topics {
    > hr:last-child {
      display: none;
    }

    > h3 {
      margin: 0;
      padding: 1.2rem 1.2rem 1.2rem 0;
      font-weight: bold;
      font-size: 1.6rem;
    }
  }

  ._gap24 {
    gap: 1.6rem;
  }

  .single-topic {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  .header-content {
    display: flex;
    gap: 0.8rem;

    > h5 {
      font: var(--Regular-200);
      color: var(--text-primary-color);
    }
  }

  .topic-content {
    display: flex;
    gap: 0.8rem;

    > .title {
      font: var(--Medium-400);
      color: var(--text-secondary-color);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .topic-permition {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.-hide {
      display: none;
    }
  }

  .replies-number {
    font: var(--Regular-200);
  }

  @media (max-width: 768px) {
    ._gap24 {
      background-color: inherit;
      border: none;
    }

    .category-page-content {
      gap: 0;
    }

    .tabs-and-filters {
      display: none;
    }
  }
</style>
