<script lang='ts'>
  import { tick } from 'svelte'

  import { languages } from '@/config/languages'

  import { getIsoFromId } from '@/helpers/apiCall'

  import CountryFlag from '@/components/icons/CountryFlag.svelte'

  import { LanguagesList } from '@/definitions/langoid'

  export let iso = '',
    id: number = 0,
    variant: 'full' | 'small' | 'normal' = 'normal',
    clickOnFlag = (id: number) => { },
    active = false,
    rand = 0,
    tabindex = 0,
    noClick = false

  let {
    country,
    color,
    background,
    showText
  } = getDetails()
  const MAX_RAND_NUMBER = 1e6

  $:if (rand) {
    const tmp = getDetails()
    country = tmp.country
    color = tmp.color
    background = tmp.background
    showText = tmp.showText
  }

  if (rand === 0) {
    rand = Math.round(Math.random() * MAX_RAND_NUMBER)
  }
  if (iso === '') {
    iso = getIsoFromId(id)
  }

  function getDetails () {
    const pairs: Record<string, string> = {
      afr: 'za', // Afrikaans
      aln: 'al', // Albanian
      amh: 'et', // Amharic
      ara: 'sa', // Arabic
      azj: 'az', // Azerbaijani
      bel: 'by', // Belarusian
      ben: 'bd', // Bengali
      bho: 'in', // Bhojpuri
      bos: 'ba', // Bosnian
      bul: 'bg', // Bulgarian
      cat: 'es', // Catalan
      ces: 'cz', // Czech
      cjy: 'cn', // Chinese/Changzhou
      ckb: 'iq', // Kurdish
      cmn: 'cn', // Chinese/Mandarin
      cze: 'cz', // Czech
      dan: 'dk', // Danish
      deu: 'de', // German
      ekk: 'ee', // Estonian
      ell: 'gr', // Greek
      eng: 'gb', // English
      est: 'ee', // Estonian
      fas: 'ir', // Persian
      fin: 'fi', // Finnish
      fra: 'fr', // French
      glg: 'es', // Galician
      guj: 'in', // Gujarati
      hak: 'cn', // Hakka
      hat: 'ht', // Haitian
      hau: 'ng', // Hausa
      heb: 'il', // Hebrew
      hil: 'ph', // Hiligaynon
      hin: 'in', // Hindi
      hmn: 'cn', // Hmong
      hrv: 'hr', // Croatian
      hun: 'hu', // Hungarian
      hye: 'am', // Armenian
      ibo: 'ng', // Igbo
      ilo: 'ph', // Ilokano
      ind: 'id', // Indonesian
      ita: 'it', // Italian
      jav: 'id', // Javanese
      jpn: 'jp', // Japanese
      kan: 'in', // Kannada
      kat: 'ge', // Georgian
      kaz: 'kz', // Kazakh
      khk: 'mn', // Khalkha
      khm: 'kh', // Khmer
      kin: 'rw', // Kinyarwanda
      kir: 'kg', // Kyrgyz
      kor: 'kr', // Korean
      kur: 'iq', // Kurdish
      lao: 'la', // Lao
      lav: 'lv', // Latvian
      lit: 'lt', // Lithuanian
      lus: 'in', // Lushai
      lvs: 'lv', // Latvian
      mal: 'in', // Malayalam
      man: 'ml', // Mandingo
      mar: 'in', // Marathi
      mkd: 'mk', // Macedonian
      mni: 'in', // Manipuri
      msy: 'my', // Misigna
      mya: 'mm', // Burmese
      myv: 'ru', // Erzya
      nan: 'cn', // Chinese/NanNing
      nep: 'np', // Nepali
      nld: 'nl', // Dutch
      nor: 'no', // Norwegian
      npi: 'in', // Nepali
      nya: 'mw', // Nyanja
      ory: 'in', // Oriya
      pap: 'aw', // Papiamento
      pbt: 'af', // Pashto/Northern
      pcm: 'ng', // Nigerian Pidgin
      pes: 'ir', // Persian
      plt: 'mg', // Plateau Malagasy
      pnb: 'pk', // Punjabi/Western
      pol: 'pl', // Polish
      por: 'pt', // Portuguese
      ron: 'ro', // Romanian
      rus: 'ru', // Russian
      sin: 'lk', // Sinhala
      slk: 'sk', // Slovak
      slv: 'si', // Slovenian
      sna: 'zw', // Shona
      snd: 'pk', // Sindhi
      som: 'so', // Somali
      spa: 'es', // Spanish
      srp: 'rs', // Serbian
      sun: 'id', // Sundanese
      swe: 'se', // Swedish
      swh: 'tz', // Swahili
      tam: 'in', // Tamil
      tat: 'ru', // Tatar
      tel: 'in', // Telugu
      tgk: 'tj', // Tajik
      tha: 'th', // Thai
      tuk: 'tm', // Turkmen
      tur: 'tr', // Turkish
      uig: 'cn', // Uyghur
      ukr: 'ua', // Ukrainian
      urd: 'pk', // Urdu
      uzn: 'uz', // Uzbek/Northern
      vie: 'vn', // Vietnamese
      war: 'ph', // Waray
      wuu: 'cn', // Wu Chinese
      xho: 'za', // Xhosa
      yue: 'cn', // Yue Chinese
      zho: 'cn', // Chinese
      zsm: 'my', // Malay
      zul: 'za', // Zulu
    }
    let country = '',
      color = 'black',
      background = 'transparent',
      showText = true
    if (['ceb', 'tgl'].includes(iso)) {
      country = 'ph'
      color = '#fff'
    } else if (iso === 'arb') {
      background = 'green'
      color = '#fff'
    } else if (iso === 'yor') {
      country = 'ng'
      color = 'black'
    } else if (pairs[iso]) {
      country = pairs[iso]
      showText = false
    } else {
      color = 'black'
      background = '#fff'
    }
    tick()
    return {
      background,
      color,
      country,
      showText
    }
  }

  const title = languages.find((l: LanguagesList) => l.iso === iso)?.name || ''
</script>
{#if country}
  <div
    class='flag-wrapper'
    class:-active={active}
    class:-full={variant === 'full'}
    class:-noClick={noClick}
    class:-small={variant === 'small'}
    data-id={id}
    data-rand={rand}
    role='button'
    {tabindex}
    {title}
    on:click={() => clickOnFlag(id)}
    on:keypress={() => {}}
    style:color
    style:background
  >
    <CountryFlag {country} size={variant==='small'?16:24} />
    {#if showText}
      <div class='language'>{iso}</div>
    {/if}
    {#if variant === 'full'}
      <div class='languageName'>{title}</div>
    {/if}
  </div>
{:else}
  <div
    class='flag-wrapper'
    class:-active={active}
    class:-small={variant === 'small'}
    data-id={id}
    data-rand={rand}
    role='button'
    {tabindex}
    on:click={() => clickOnFlag(id)}
    on:keypress={() => {}}
    style:color
    style:background
  >
    <div class='textOnly'>{iso}</div>
  </div>
{/if}
<style lang='scss'>
  .flag-wrapper {
    position: relative;
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    font-weight: bold;
    font-size: 1rem;
    font-variant: small-caps;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;

    > .language {
      position: absolute;
      right: 0.5rem;
      bottom: 0.2rem;
    }

    > .languageName {
      position: absolute;
      top: 0.2rem;
      left: 0.2rem;
      color: var(--text-primary-color);
    }

    &.-full {
      padding: 0 1rem;

      > .languageName {
        position: relative;
        top: unset;
        left: unset;
      }
    }

    &.-active {
      position: relative;
      border: none;
      outline: solid var(--Primary-Medium) 0.2rem;
    }

    &.-small {
      width: 1.6rem;
      height: 1.6rem;

      > .language {
        position: relative;
        right: auto;
        bottom: 0;
        font-size: 0.8rem;
        color: var(--Gray-Darker);
      }
    }

    &.-noClick {
      pointer-events: none;
    }
  }
</style>
