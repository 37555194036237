<script lang='ts'>
  import { link } from 'svelte-routing'

  export let user = {} as {
    avatar: string,
    id: number,
    id_user: number,
    nick: string
  }
</script>
<div class='user-badge'>
  <a href='/profile/{user.id || user.id_user}' rel='nofollow' use:link>
    <img class='image' alt='' height='20' src={user.avatar} /> {user.nick}
  </a>
</div>

<style lang='scss'>
  .user-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;
    font-size: 1.2rem;

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      > .image {
        width: auto;
        max-width: 4rem;
        max-height: 2.4rem;
      }
    }
  }
</style>
