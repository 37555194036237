<script lang='ts'>
  import { _ } from '@/helpers/i18n'

  import UserAvatar from '@/components/social/UserAvatar.svelte'

  export let user = {
    avatar: '',
    id: 0,
    level: 0,
    nick: ''
  }
</script>
<div class='translated-by _row'>
  <p>{$_('mix.translatedBy')}:</p>
  <UserAvatar metaDataVariant='right' {user} />
</div>
<style lang='scss'>
  .translated-by {
    > p {
      width: max-content;
      font: var(--Regular-200);
      color: var(--text-primary-color);
    }

    > :global(.user-badge) {
      margin: 0;
      font-size: 1.2rem;
    }

    > :global(.user-badge img) {
      display: none;
    }
  }

  @media (max-width: 414px) {
    .translated-by {
      grid-column: 1/3;
      order: 1;
      margin-bottom: 1rem;
    }
  }
</style>
