<script lang='ts'>
  import { onDestroy } from 'svelte'

  import { ALLOW_ARCH_DEL_EDIT_ROLE, ALLOW_ARCH_DEL_ROLE } from '@/config/constants'

  import { makeReply } from '@/helpers/forumHelpers'

  import RevisionPopup from '@/components/forum/RevisionPopup.svelte'
  import Kebab from '@/components/ui/Kebab.svelte'
  import KebabItem from '@/components/ui/KebabItem.svelte'
  import ReportButton from '@/components/ui/ReportButton.svelte'

  import { Topic, TopicReplay } from '@/definitions/langoid'
  import { _ } from '@/libs/i18n'
  import { userStorredConfig } from '@/store'

  export let reply: TopicReplay
  export let isReplyTranslation: boolean = false
  export let topic: Topic
  export let vote: (topicId: number, replyId: number, type?: string, description?: string, hm?: string) => void
  export let markDownvote: (replyId: number) => boolean

  let user = $userStorredConfig
  const unsubscribe = userStorredConfig.subscribe((value) => {
    if (Object.keys(value).length) {
      user = value
    }
  })

  onDestroy(() => {unsubscribe()})
</script>

<Kebab>
  <ReportButton
    category='forumReply'
    rel={reply.id}
    selected={markDownvote(reply.id)}
    on:report={(e) => {
      vote(topic.id, reply.id, e.detail?.type, e.detail?.description, undefined)
    }}
  />
  {#if user.is_admin >= ALLOW_ARCH_DEL_ROLE}
    {#if reply.is_archived}
      <KebabItem
        action={() => { makeReply('unarchived', reply.id); reply.is_archived = 0 }}
        icon='FolderMinus'
        label={$_('forum.unarchive')}
      />
    {:else}
      <KebabItem
        action={() => { makeReply('archived', reply.id); reply.is_archived = 1 }}
        icon='Folder'
        label={$_('forum.archive')}
      />
    {/if}
  {/if}
  {#if user.is_admin >= ALLOW_ARCH_DEL_ROLE || (user.id === reply.id_user && !isReplyTranslation) ||
  (reply.translatedBy?.id === reply.id_user && isReplyTranslation)}
    <KebabItem
      action={() => makeReply('deleted', reply.id, isReplyTranslation ? reply.id_language : 0)}
      icon='Trash'
      label={$_('forum.delete')}
    />
  {/if}
  {#if (user.id === reply.id_user && !isReplyTranslation) ||
  (reply.translatedBy?.id === user.id && isReplyTranslation) || user.is_admin >= ALLOW_ARCH_DEL_EDIT_ROLE}
    <KebabItem
      href={'/forum-edit-reply/' + topic.id + '/' + reply.id + (isReplyTranslation ? `/${reply.id_language}` : '')}
      icon='PencilSimple'
      label={$_('forum.edit')}
    />
  {/if}
  <RevisionPopup revision={reply} type={isReplyTranslation ? 'reply_translation' : 'reply'} {user} />
</Kebab>
