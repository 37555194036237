<script lang='ts'>
  import { link } from 'svelte-routing'

  import { MAX_LINK_LENGTH, MAX_TOPIC_IN_LIST_LENGTH } from '@/config/constants'

  import { getCookie, getLearningLanguageId } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { isAllowed } from '@/helpers/forumHelpers'
  import { _ } from '@/helpers/i18n'
  import { slugify, truncate, truncateUrl } from '@/helpers/mixHelpers'

  import Flag from '@/components/forum/Flag.svelte'
  import ForumTabFilters from '@/components/forum/ForumTabFilters.svelte'
  import LanguagesFilter from '@/components/forum/LanguagesFilter.svelte'
  import Icon from '@/components/icons/Icon.svelte'
  import IconLocked from '@/components/icons/IconLocked.svelte'
  import IconTopic from '@/components/icons/IconTopic.svelte'
  import UserAvatar from '@/components/social/UserAvatar.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import EmptyState from '@/components/ui/EmptyState.svelte'
  import ForumTabs from '@/components/ui/ForumTabs.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'

  import { UntranslatedPostsApi, UserAvatarData } from '@/definitions/langoid'
  import { userStorredConfig } from '@/store'

  export let iso = ''
  export let group: 'untranslated' | 'popular' | 'latest' | 'bookmarks' = 'untranslated'

  let users: Record<number, UserAvatarData> = {},
    activeTab = 'topics',
    contentList: any = [],
    morePosts = [],
    offset = 0,
    selectedLanguage: number = getLearningLanguageId(),
    url = ''
  const jwt = getCookie('jwt')

  const getUrl = (group: string) => {
    if (group === 'bookmarks') {
      url = 'forum/userBookmarks'
    } else if (group === 'untranslated') {
      url = 'forum/untranslatedPosts'
    } else if (group === 'latest') {
      url = 'forum/latestForumActions'
    }
    return url
  }

  const offsetLimit: number = 20
  const tabNames: Record<string, string> = {
    replies: 'activity.repliesList',
    topics: 'activity.topicsList'
  }

  const breads = [
    { text: '', url: `/${iso}` },
    { text: translation(group, 'mainTitle') },
  ]

  loadList()

  async function loadList () {
    const data: UntranslatedPostsApi = await fetchData(getUrl(group), { activeTab, offset, selectedLanguage })
    contentList = data.contentList
  }

  async function loadMore () {
    offset += offsetLimit
    const data: UntranslatedPostsApi = await fetchData(getUrl(group), { activeTab, offset, selectedLanguage })
    morePosts = data.contentList
    contentList = [...contentList, ...morePosts]
  }

  function changeTab (tabName: string) {
    offset = 0
    activeTab = tabName
    loadList()
  }

  function changeLanguage (lang: number) {
    offset = 0
    selectedLanguage = lang
    loadList()
  }

  $: if (contentList) {
    users = contentList.reduce((acc: any, obj: any) => {
      const { id, name, nick, avatar, level } = obj
      acc[obj.id] = { avatar, id, level, name, nick }
      return acc
    }, {})
  }

  function translation (pageGroup: string, type: string) {
    if (type === 'emptyState') {
      if (pageGroup === 'untranslated') return 'forum.withoutContent'
      else if (pageGroup === 'bookmarks') return 'activity.emptyNote'
      else if (pageGroup === 'latest') return 'activity.emptyNote'
    } else if (type === 'mainTitle') {
      if (pageGroup === 'untranslated') return 'forum.untranslated'
      else if (pageGroup === 'bookmarks') return 'forum.userBookmarks'
      else if (pageGroup === 'latest') return 'forum.latest'
    }

    throw new Error('Translation not found')
  }
</script>
<Breadcrumb {breads} filters>
  <h2 class='mainTitle'>{$_(translation(group, 'mainTitle'))}</h2>
  <ForumTabFilters {group} {iso} />
  <div slot='filters' class='_breadcrumbicons'>
    {#if jwt}
      <LanguagesFilter />
    {/if}
  </div>
</Breadcrumb>
<div class='tabs-and-filters'>
  <ForumTabFilters {group} {iso} />
  {#if jwt}
    <LanguagesFilter />
  {/if}
</div>

<div class='_gap24'>
  <div class='tabs-and-language'>
    <ForumTabs {activeTab} {changeTab} {tabNames} />
    {#if group === 'untranslated' && jwt}
      <div class='select-language'>
        <label for='languages'>{$_('forum.notTranslatedLanguage')}</label>
        <LanguageSelect onChange={changeLanguage} bind:selectedLanguage />
      </div>
    {/if}
  </div>
  {#if contentList.length === 0}
    <div class='_vertical-small -active'>
      <EmptyState src='/images/empty-file.svg'>
        <div class='empty-state'>{translation(group, 'emptyState')}</div>
      </EmptyState>
    </div>
  {:else}
    {#each contentList as contentList}
      {@const permissions = isAllowed(contentList, $userStorredConfig)}
      <div class='single-topic'>
        <div class='header-content'>
          <h5>{contentList.categoryGroup} / {contentList.categoryTitle}</h5>
          <span>•</span>
          <div class='_row'>
            <!--
              <FlagsList
              active={contentList.currentLanguage}
              clickOnFlag={(lang) => { clickOnFlag(lang, contentList.topicId) }}
              languages={reorderLanguages(translations[contentList.topicId] ? translations[contentList.topicId].toString().split(',').map(Number) : [], myLanguages)}
              {topic}
              />
              {#if translations[contentList.topicId]?.toString().split(',').map(Number)}
                •
              {/if}
              <Flag
                id={contentList.id_language}
                active={contentList.currentLanguage === contentList.id_language}
                clickOnFlag={(lang) => { clickOnFlag(contentList.id_language, contentList.topicId) }}
            />-->
          </div>
        </div>
        <div class='topic-content'>
          <div class='topic-permition'>
            {#if contentList.target_language}
              <Flag id={contentList.target_language} variant='small' />
            {/if}
            {#if permissions[0] && contentList.is_locked}
                <span class='smallIcons'>
                  <Icon icon='Lock' />
                </span>
            {/if}
          </div>
          {#if permissions[0]}
            {#if activeTab === 'topics'}
              <a class='title' href={`/${iso}/t/${slugify(truncateUrl(contentList.content, MAX_LINK_LENGTH))}/${contentList.topicId}`} use:link>
                <IconTopic type={contentList.type} />{truncate(contentList.content, MAX_TOPIC_IN_LIST_LENGTH)}</a>
            {:else}
              <a
                class='title'
                href={`/${iso}/t/${slugify(truncateUrl(contentList.topic_content, MAX_LINK_LENGTH))}/${contentList.topicId}/?reply=${contentList.replyId}`}
                use:link
              >
                <IconTopic type={contentList.type} /> {@html truncate(contentList.content, MAX_TOPIC_IN_LIST_LENGTH)}</a>
            {/if}
          {:else}
            <h4 class='title'>
              <IconLocked {contentList} fill='red' />
              <IconTopic type={contentList.type} /> {contentList.content}</h4>
          {/if}
        </div>
        <div class='_row'>
          <UserAvatar
            avatarVariant='smallAvatar'
            date={contentList.action_time}
            dateVariant='withDate'
            metaDataVariant='right'
            user={users[contentList.id_user]}
          />
          {#if activeTab === 'topics'}
            <span>•</span>
            <div class='replies-number'>
              <span>{contentList.num_of_replies}</span>
              <span>{$_('forum.replies')}</span>
            </div>
          {/if}
        </div>
      </div>
      <hr />
      <!--      <div class='row _horizontal'>
              {#key contentList.originalLanguage}
                <Flag id={contentList.originalLanguage} />
              {/key}
              <span class='timeAto'>{timeAgo(contentList.action_time)}</span>
              <div>
                {#if activeTab === 'topics'}
                  {@const actionLink = '/' + iso + '/t/' + slugify(contentList.originalContent) + '/' + contentList.topicId}
                  <a href={actionLink} use:link>{truncateUrl(contentList.originalContent, MAX_LINK_LENGTH)}</a>
                {:else}
                  {@const actionLink = '/' + iso + '/t/' + slugify(contentList.originalContent) + '/' + contentList.topicId + '?reply=' + contentList.replyId}
                  <a href={actionLink} use:link>{truncateUrl(contentList.originalContent, MAX_LINK_LENGTH)}</a>
                {/if}
              </div>
            </div>-->
    {/each}
  {/if}
</div>
{#if contentList.length < offsetLimit || contentList.length < offset + offsetLimit}
  <button class='load-more -disabled' type='button' on:click={loadMore}>Load more</button>
{:else}
  <button class='load-more -primary' type='button' on:click={loadMore}>Load more</button>
{/if}
<style lang='scss'>
  .tabs-and-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .select-language {
    position: absolute;
    top: 1.6rem;
    right: 2.4rem;
  }

  .single-topic {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  .header-content {
    display: flex;
    gap: 0.8rem;

    > h5 {
      font: var(--Regular-200);
      color: var(--Gray-Dark);
    }
  }

  .topic-content {
    display: flex;
    gap: 0.8rem;

    > .title {
      font: var(--Medium-400);
      color: var(--text-secondary-color);

      &:hover {
        text-decoration-line: underline;
      }
    }
  }

  .topic-permition {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .replies-number {
    font: var(--Regular-200);
  }

  .load-more {
    &.-disabled {
      display: none;
    }

    &.-primary {
      margin: 3rem auto 0;
    }
  }

  @media(max-width: 768px) {
    ._gap24 {
      padding: 0;
      background-color: transparent;
      border: none;
    }

    .tabs-and-filters {
      display: none;
    }

    .tabs-and-language {
      display: flex;
      flex-direction: column-reverse;
      gap: 1.6rem;
      align-items: start;
      margin-inline: -1.6rem;
    }

    .select-language {
      position: relative;
      top: unset;
      right: unset;
      width: 100%;
      padding: 0 1.6rem;
    }

    .mainTitle {
      min-width: 16rem;
    }
  }
</style>
