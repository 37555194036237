<script lang='ts'>
  import Icon from '@/components/icons/Icon.svelte'

  import { _ } from '@/libs/i18n'

  export let showNativeAlert = false,
    showForEng = false
</script>
<span class='language-suggestion' class:-showInfo={showNativeAlert} class:-showWarning={showForEng}>
  <span class='question'><Icon icon='Question' size={16} /></span>
  {#if showNativeAlert}
    <span class='languageSuggestion'>{$_('forum.alertForNative')}</span>
  {:else if showForEng}
    <span class='languageSuggestion'>{$_('forum.alertForEng')}</span>
  {/if}
</span>
<style lang='scss'>
  .language-suggestion {
    display: none;

    &.-showInfo, &.-showWarning {
      display: flex;
      gap: 8px;
      align-items: flex-start;
      justify-content: center;
      padding: 0.4rem 0.8rem;
      font: 400 1.2rem/1.33 Noto Sans;
      background: var(--warning-background);
      border-right: 0.15rem solid var(--warning-text);
      border-left: 0.15rem solid var(--warning-text);
    }

    > .question {
      color: var(--Warning-Medium);
    }
  }
</style>
