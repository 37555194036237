import { get } from 'svelte/store'
import { navigate } from 'svelte-routing'

import { ALLOW_ALL_ADMIN_LEVEL, ALLOW_WRITE_LOCKED_POSTS } from '@/config/constants'

import { fetchData } from '@/helpers/fetchHelpers'
import { _ } from '@/helpers/i18n'
import { toInt } from '@/helpers/mixHelpers'

import { CategoryTopic, ForumLanguage, TopicReplay, TranslateItem } from '@/definitions/langoid'
import { forumConfig } from '@/store'

export const isAllowed = (topic: any, userData: any) => {
  let canRead = true
  let canWrite = true
  if (userData.is_admin === ALLOW_ALL_ADMIN_LEVEL) {
    return [true, true]
  }
  if (topic.id_user === userData.id) {
    return [true, true] // topic createor can read and write on his topic
  }

  if (topic.admin_read_level > 0 && userData.is_admin < topic.admin_read_level) {
    canRead = false
  } else if (topic.read_level > 0 && userData.level < topic.read_level) {
    canRead = false
  }

  if (topic.is_locked) {
    return [canRead, userData.is_admin >= ALLOW_WRITE_LOCKED_POSTS] // If locked, only admins with level 8+ can write
  }

  if (topic.is_archived) {
    return [canRead, false]
  }
  if (topic.write_level > 0 && userData.level < topic.write_level) {
    canWrite = false
  }
  if (topic.admin_write_level > 0 && userData.is_admin < topic.admin_write_level) {
    canWrite = false
  }
  return [canRead, canWrite]
}

export const getUserObject = (obj: any) => {
  return {
    avatar: obj.avatar,
    id: obj.userId,
    level: obj.level,
    name: obj.name,
    nick: obj.nick,
    points: obj.points
  }
}

export const makeTopic = async (type: string, id: number, language = 0) => {
  if (type === 'deleted') {
    const message = language === 0 ? 'forum.sureDeleteTopic' : 'forum.sureDeleteTopicTranslation'
    if (!confirm(get(_)(message))) {
      return false
    }
  }
  await fetchData('forum/makeTopic', { id, language, type })
  if (type === 'deleted') {
    if (language === 0) navigate('/forum')
    else location.reload()
  }
  return true
}

export const makeReply = async (type: string, id: number, language = 0) => {
  if (type === 'deleted') {
    const message = language === 0 ? 'forum.sureDeleteReply' : 'forum.sureDeleteReplyTranslation'
    if (!confirm(get(_)(message))) {
      return false
    }
  }
  await fetchData('forum/makeReply', { id, language, type })
  if (type === 'deleted') {
    location.reload()
  }
  return true
}

export const reorderLanguages = (languages: number[], myLanguages: number[]) => {
  const ret: number[] = []
  for (const lang of myLanguages) {
    if (languages.includes(lang)) {
      ret.push(lang)
    }
  }
  for (const lang of languages) {
    if (!(ret.includes(lang))) {
      ret.push(lang)
    }
  }
  return ret
}

export function extractMention (text: string, users: { id: number; name: string }[]) {
  const mentionPrefix = '@'
  for (let i = 0; i < users.length; i++) {
    const user = users[i]
    const username = user.name
    let index = 0
    while (index < text.length) {
      const mentionIndex = text.indexOf(mentionPrefix + username, index)
      if (mentionIndex === -1) {
        break // no more mentions
      }
      const mentionEndIndex = mentionIndex + mentionPrefix.length + username.length
      const mention = text.substring(mentionIndex + mentionPrefix.length, mentionEndIndex)
      const link = `@${mention}</a>`
      const linkFull = `<a href='/profile/${user.id}'>@${mention}</a>`
      if (text.indexOf(link) === -1) {
        text = text.substring(0, mentionIndex) + linkFull + text.substring(mentionEndIndex)
        index = mentionIndex + linkFull.length
      } else {
        index = mentionEndIndex // already a link
      }
    }
  }
  return text
}

export const uniqueLangs = (languages: ForumLanguage[] = []) => {
  const uniqueLangs = new Set()
  return languages.filter(el => {
    if (toInt(el?.lang) !== 0 && !uniqueLangs.has(el.lang)) {
      uniqueLangs.add(el.lang)
      return true
    }
    return false
  })
}

export const removeEmpty = (languages: ForumLanguage[] = []) => {
  return languages.filter(el => el?.lang)
}

export const mapTranslations = (topic: CategoryTopic, myTranslations: any) => {
  const myLanguages = get(forumConfig).known.map(el => toInt(el.lang))
  const translations = myTranslations[topic.topicId] || []
  const first = myLanguages.find((el: number) => {
    for (let i = 0; i < translations.length; i++) {
      if (toInt(translations[i].id_language) === el) {
        return true
      }
    }
    return false
  })
  if (first) {
    topic.content = translations.find((el: any) => toInt(el.id_language) === toInt(first)).translation
    topic.currentLanguage = first
  } else if (translations?.length) {
    topic.content = translations[0].translation
    topic.currentLanguage = translations[0].id_language
  } else {
    topic.currentLanguage = topic.id_language
  }
  return topic
}

export const clickOnTopicReplyFlag = async (replies: Record<number, TopicReplay[]>, language: number, topicId: number, replyId: number, parentId = 0, original = false) => {
  const data: TranslateItem = await fetchData('forum/translateSingleItem', {
    language,
    original,
    replyId,
    topicId
  })
  replies[parentId] = replies[parentId].map((reply) => {
    if (reply.id === replyId) {
      reply.content = data.content
      reply.id_language = data.id_language
      reply.translatedBy = {
        avatar: data.avatar,
        id: data.id_user,
        level: data.level,
        nick: data.nick
      }
      reply.revision_count = data.revision_count
      reply.revision_details = data.revision_details
    }
    return reply
  })
  return replies
}
