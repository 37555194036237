<script lang='ts'>
  import { link } from 'svelte-routing'

  import { EXECUTION_TIME_HACK, MIN_REQUIRED_TEXT_LENGTH } from '@/config/constants'

  import { getIsoFromId } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { toInt } from '@/helpers/mixHelpers'
  import { notifier } from '@/helpers/notifier'

  import Flag from '@/components/forum/Flag.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'

  import { QuillOptions, QuillType, QuillWrapper, TranslateData } from '@/definitions/langoid'

  let quill: QuillType

  export let topicId = 0,
    replyId = 0

  const options: QuillOptions = {}
  let topicData: TranslateData = {} as TranslateData
  let title = ''
  let originalText = ''
  let rowNumber = 0
  let language = 0
  let content = { html: '', text: '' },
    translationSent = false,
    activeLanguage = 0
  let editContent = !!replyId
  const alreadyExistMessage = $_('forum.translationExists')

  function loadData (data: any) {
    topicData = data
    if (toInt(activeLanguage) === 0) activeLanguage = data.id_language
    import('@/helpers/svelteQuill').then((el: QuillWrapper) => {
      quill = el.quill
      setTimeout(() => {
        const quillEditor = document.querySelector('.ql-editor')
        if (data.translatedContent) {
          originalText = data.translatedContent || ''
          rowNumber = Math.max(1, Math.ceil(originalText.split(' ').length / 7))
          if (replyId && quillEditor) quillEditor.innerHTML = data.translatedContent
        } else {
          originalText = topicData.content || ''
          rowNumber = Math.max(1, Math.ceil(originalText.split(' ').length / 7))
          if (replyId && quillEditor) quillEditor.innerHTML = topicData.content
        }
      }, EXECUTION_TIME_HACK)
    })
  }

  function editOriginalContent () {
    title = originalText
    editContent = true
  }

  function loadTranslation (language: number) {
    activeLanguage = language
    fetchTranslation(language, replyId, topicId)
  }

  async function fetchTranslation (language: number, replyId: number, topicId: number) {
    const data = await fetchData('forum/translateLoad', { language, replyId, topicId })
    loadData(data)
  }

  fetchTranslation(0, replyId, topicId)

  function saveTranslation () {
    if (language === 0) {
      notifier.error($_('forum.chooseLanguage'))
      return
    }
    if (topicData.existing?.includes(language)) {
      notifier.error(alreadyExistMessage)
    }
    if (!replyId && title.length <= MIN_REQUIRED_TEXT_LENGTH) {
      notifier.error($_('forum.titleTooShort'))
      return
    }
    if (replyId && content.text.length <= MIN_REQUIRED_TEXT_LENGTH) {
      notifier.error($_('forum.contentTooShort'))
      return
    }
    const sentContent = replyId ? content.html : title
    if (sentContent === topicData.content) {
      if (!confirm($_('forum.sameTranslation'))) {
        return
      }
    }
    fetchData('forum/translateSave', { content: sentContent, language, replyId, topicId }).then(() => {
      translationSent = true
    })
  }

  const emptyString = ''

  function setLanguage (languageId: number): void {
    const choosenLanguage = languageId
    if (topicData.existing?.includes(choosenLanguage)) {
      notifier.error(alreadyExistMessage)
    }
    language = choosenLanguage
  }

  const setContent = (e: CustomEvent) => {
    if (editContent) {
      content = e.detail
    }
  }
</script>
<Breadcrumb>
  <h2>{$_('forum.translatePost')}</h2>
</Breadcrumb>
<div class='_gap24'>
  <h2 class='_desktopOnly'>{$_('forum.translatePost')}</h2>
  <hr />
  {#if !translationSent}
    <form class='_vertical' on:submit|preventDefault={saveTranslation}>
      <div class='languages-section'>
        <div class='original-and-translations'>
          {#if topicData.existing?.length}
            <div class='flag-wrapper _row'>
              <h6>{$_('mix.original')}</h6>
              <Flag
                active={activeLanguage === topicData.id_language}
                clickOnFlag={() => {loadTranslation(topicData.id_language)}}
                iso={getIsoFromId(topicData.id_language)}
              />
            </div>
            {#if topicData.existing.length > 1}
              <div class='_row'>
                <h6>{$_('forum.existingTranslations')}</h6>
                {#each topicData.existing as existing}
                  {#if existing !== topicData.id_language}
                    <Flag
                      active={activeLanguage === existing}
                      clickOnFlag={() => {loadTranslation(existing)}}
                      iso={getIsoFromId(existing)}
                    />
                  {/if}
                {/each}
              </div>
            {/if}
          {/if}
        </div>
        <div class='translation-language -desktop'>
          <h6>{$_('forum.translationLanguage')}</h6>
          <LanguageSelect onChange={setLanguage} selectedLanguage={language} />
        </div>
      </div>
      <div class='original-and-translation-text'>
        <div class='original-content'>
          <h6>{$_('forum.originalPost')}</h6>
          <div class='originalText text-editor'>{@html originalText}</div>
        </div>
        <hr />
        <div class='translation-content'>
          <div class='translation-language -mobile'>
            <h6>{$_('forum.translationLanguage')}</h6>
            <LanguageSelect onChange={setLanguage} selectedLanguage={language} />
          </div>
          {#if !replyId}
            <div class='translation-text'>
              <h6>{$_('mix.translation')}</h6>
              <textarea rows={rowNumber} bind:value={title} />
            </div>
            <button class='_transparent-blue' type='button' on:click={editOriginalContent}>Edit original content</button>
          {:else}
            {#if quill}
              <div class='translation-text'>
                <h6>{$_('mix.translation')}</h6>
                <div class='editor' on:text-change={setContent} use:quill={options} />
              </div>
            {/if}
          {/if}
        </div>
      </div>
      <input class='main-submit' type='submit' value={$_('forum.saveTranslation')} />
    </form>
  {:else}
    <h2>{$_('forum.translationSent')}</h2>
    <a
      href='/forum/{topicData.id_category}/{topicId}{replyId ? `?reply=${replyId}` : emptyString}'
      use:link
    >{$_('forum.checkTranslationHere')}</a>
  {/if}
</div>
<style lang='scss'>
  .languages-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.4rem;
  }

  .original-and-translations {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .original-and-translation-text {
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 2.4rem;

    > hr {
      display: none;
    }
  }

  ._transparent-blue {
    margin-top: 0.8rem;
    background-color: transparent;
  }

  .original-content, .translation-text, .translation-language {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }

  .translation-language {
    &.-mobile {
      display: none;
    }
  }

  .original-content {
    > .originalText {
      padding: 0.8rem 1.2rem;
      font: var(--Regular-400);
      border: 0.1rem solid var(--Gray-Light);
      border-radius: 0.8rem;
    }
  }

  .translation-text {
    > textarea {
      width: 100%;
      resize: vertical;
    }
  }

  @media(max-width: 768px) {
    .languages-section {
      display: flex;
      flex-direction: column;
    }

    .translation-content {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
    }

    .translation-language {
      &.-desktop {
        display: none;
      }

      &.-mobile {
        display: block;
      }
    }

    .original-and-translation-text {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;

      > hr {
        display: block;
      }
    }

    .flag-wrapper {
      min-height: auto;
    }

    ._gap24 {
      padding: 0;
      border: none;

      > hr {
        display: none;
      }
    }

    .main-submit {
      width: 100%;
    }
  }
</style>
