<script lang='ts'>
  import { onDestroy } from 'svelte'

  import { UI_VISIBLE_LANGUAGES } from '@/config/constants'

  import { reorderLanguages } from '@/helpers/forumHelpers'
  import { clickOutside, toInt } from '@/helpers/mixHelpers'

  import Flag from '@/components/forum/Flag.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'

  import { LanguageGroups } from '@/definitions/langoid'
  import { forumConfig } from '@/store'

  export let languages: number[] = [],
    clickOnFlag = (flag: number) => {},
    active = 0

  let firstTwoLanguages: number[] = languages.slice(0, UI_VISIBLE_LANGUAGES)
  let otherLanguages: number[] = languages.slice(UI_VISIBLE_LANGUAGES)
  const unsubscribe = forumConfig.subscribe((config: LanguageGroups) => {
    if (config.known.length) {
      languages = reorderLanguages(languages, config.known.map(el => toInt(el.lang)))
      firstTwoLanguages = languages.slice(0, UI_VISIBLE_LANGUAGES)
      otherLanguages = languages.slice(UI_VISIBLE_LANGUAGES)
    }
  })

  let showOtherLangs = false

  onDestroy(() => {unsubscribe()})
</script>
<div class='flags-list'>
  {#each firstTwoLanguages as language, index(language)}
    <Flag id={language} active={active === language} {clickOnFlag} />
  {/each}
  {#if otherLanguages?.length}
    <div class='other-langs-wrapper'>
      <FakeButton onClick={() => { showOtherLangs = !showOtherLangs }}>
        <div class='numberOfLanguages'>
          {#if otherLanguages.length}+{otherLanguages.length}{/if}
        </div>
      </FakeButton>
      <div
        class='langs-list'
        class:-hidden={!showOtherLangs}
        on:click_outside={() => { showOtherLangs = false }}
        use:clickOutside
      >
        {#each otherLanguages as language, index(language)}
          <Flag id={language} active={active === language} {clickOnFlag} />
        {/each}
      </div>
    </div>
  {/if}
</div>
<style lang='scss'>
  .flags-list {
    display: flex;
    gap: 0.4rem;
    align-items: center;
  }

  .other-langs-wrapper {
    position: relative;
    margin-left: 0.6rem;
    cursor: pointer;
  }

  .langs-list {
    position: absolute;
    top: 3rem;
    left: -9.6rem;
    z-index: 10;
    max-width: 12.8rem;
    padding: 1.4rem 1.2rem;
    text-align: center;
    background: var(--Base-White);
    border: 0.1rem solid var(--Gray-Medium);
    border-radius: 0.8rem;
    &.-hidden {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .other-langs-wrapper {
      display: inline-block;
    }
  }
</style>
