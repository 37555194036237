<script lang='ts'>
  import { link, navigate } from 'svelte-routing'

  import { getCookie } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { slugify } from '@/helpers/mixHelpers'

  import Flag from '@/components/forum/Flag.svelte'
  import FlagsListCategory from '@/components/forum/FlagsListCategory.svelte'
  import ForumTabFilters from '@/components/forum/ForumTabFilters.svelte'
  import LanguagesFilter from '@/components/forum/LanguagesFilter.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'

  import { ForumCategory, ForumLanguage, ForumTranslation } from '@/definitions/langoid'

  export let iso = ''
  let categories: Record<string, ForumCategory[]> = { 0: [] },
    allCategoriesList: { id: number, title: string }[],
    translations: Record<string, ForumTranslation[]> = {},
    known: ForumLanguage[] = [],
    knownIndexes: number[] = [],
    userId = 0
  const jwt = getCookie('jwt')
  const breads = [
    { text: '', url: `/${iso}` },
    { text: 'forum.categories' },
  ]

  const reloadCategories = () => {
    Object.keys(categories).forEach((key: string) => {
      Object.keys(categories[key]).forEach((key2: any) => {
        categories[key][key2] = mapCategory(categories[key][key2])
      })
    })
    categories = categories
  }
  $:if (categories[0].length && known?.length > 0) {
    reloadCategories()
  }

  function mapCategory (cat: ForumCategory) {
    const currentTranslations: ForumTranslation[] = Object.values(translations?.[cat.id] || {}) || []
    if (!cat.original) cat.original = { ...cat }
    cat.translations = currentTranslations || []
    if (currentTranslations?.length && cat.original.id_language !== knownIndexes[0] && !cat.alreadyLoaded) {
      const firstIndex = knownIndexes.find(el => currentTranslations.map(el => el.id_language).includes(el))
      const firstTranslation: ForumTranslation = firstIndex
        ? currentTranslations.find(el => el.id_language === firstIndex) as ForumTranslation
        : currentTranslations[0]
      cat.title = firstTranslation.title
      cat.content = firstTranslation.content
      cat.id_language = firstTranslation.id_language
      cat.alreadyLoaded = true
    }
    cat.missingTranslations = []
    knownIndexes.forEach((lang: number) => {
      if (lang !== cat.original?.id_language && !currentTranslations.find(el => el.id_language === lang)) {
        cat.missingTranslations?.push(lang)
      }
    })
    return cat
  }

  const clickOnFlag = async (language: number, category: number, subcategory: number) => {
    const data = await fetchData('forum/translateSingleCategory', { category, language, subcategory })
    Object.keys(categories).forEach(key => {
      Object.keys(categories[key]).forEach((key2: any) => {
        if (subcategory === categories[key][key2].id || (subcategory === 0 && category === categories[key][key2].id)) {
          categories[key][key2].title = data.title
          categories[key][key2].content = data.content
          categories[key][key2].id_language = language
        }
      })
    })
    reloadCategories()
  }

</script>

<Breadcrumb {breads} filters>
  {#if userId}
    <h2 class='mainTitle'>{$_('forum.userBookmarks')}</h2>
    <ForumTabFilters
      group='categories'
      {iso}
      bind:categories
      bind:translations
      bind:allCategoriesList
      bind:known
      bind:knownIndexes
      bind:userId
    />
  {/if}
  <div slot='filters' class='_breadcrumbicons'>
    {#if userId && jwt}
      <LanguagesFilter />
    {/if}
  </div>
</Breadcrumb>
<div class='tabs-and-filters'>
  <ForumTabFilters
    group='categories'
    {iso}
    bind:categories
    bind:translations
    bind:allCategoriesList
    bind:known
    bind:knownIndexes
    bind:userId
  />
  {#if userId && jwt}
    <LanguagesFilter />
  {/if}
</div>
<div class='forum-page _vertical-small'>
  {#each categories[0] as category}
    {@const categoryLink = '/' + iso + '/c/' + slugify(category.title) + '/' + category.id}
    <div class='by-category _gap24'>
      {#if categories[category.id]}
        <div class='parent-category'>
          <div class='group-wrapper'>
            <h4 class='group-category-title'>
              <span>{category.title}</span>
              <small>{category.content}</small>
            </h4>
            <div class='selected-languages -top'>
              {#if category.translations}
                <FlagsListCategory
                  active={category.id_language}
                  category={category.id}
                  {clickOnFlag}
                  languages={category.translations.map(el => el.id_language)}
                  missingTranslations={category.missingTranslations}
                />
              {/if}
              <span class='verticalLine' />
              {#if category.original}
                <Flag
                  id={category.original.id_language}
                  active={category.id_language === category.original.id_language}
                  clickOnFlag={(lang) => clickOnFlag(lang, category.id, 0)}
                  variant='small'
                />
              {/if}
            </div>
          </div>
          <hr />
          {#each categories[category.id] as subCategory}
            {@const subCategoryLink = '/' + iso + '/c/' + slugify(subCategory.title) + '/' + subCategory.id}
            <div class='single-category -subcategory'>
              <div class='category-heading' role='button' tabindex='0' on:click={() => { navigate(subCategoryLink) }} on:keypress={() => {}}>
                <div class='title-and-description'>
                  <h5 class='subCategoryTitle'><a href={subCategoryLink} use:link>{subCategory.title}</a></h5>
                  <small>{subCategory.content}</small>
                </div>
                <div class='topics-number'>
                  <div class='totalTopicNumber'>{subCategory.num_of_topics}</div>
                  <div class='numberTopicTitle'>{$_('forum.topics')}</div>
                </div>
              </div>
              <div class='selected-languages'>
                {#if subCategory.translations}
                  <FlagsListCategory
                    active={subCategory.id_language}
                    category={category.id}
                    {clickOnFlag}
                    languages={subCategory.translations?.map(el => el.id_language)}
                    missingTranslations={subCategory.missingTranslations}
                    subCategory={subCategory.id}
                  />
                {/if}
                <span class='verticalLine' />
                {#if subCategory.original}
                  <Flag
                    id={subCategory.original.id_language}
                    active={subCategory.id_language === subCategory.original.id_language}
                    clickOnFlag={(lang) => clickOnFlag(lang, category.id, subCategory.id)}
                    variant='small'
                  />
                {/if}
              </div>
            </div>
            <hr />
          {/each}
        </div>
      {:else}
        <div class='single-category -subcategory'>
          <div
            class='category-heading'
            role='button'
            tabindex='0'
            on:click={() => { navigate(categoryLink) }}
            on:keypress={() => {}}
          >
            <div class='title-and-description'>
              <h5 class='subCategoryTitle'><a href={categoryLink} use:link>{category.title}</a></h5>
              <small>{category.content}</small>
            </div>
            <div class='topics-number'>
              <div class='totalTopicNumber'>{category.num_of_topics}</div>
              <div class='numberTopicTitle'>{$_('forum.topics')}</div>
            </div>
          </div>
          <div class='selected-languages'>
            {#if category.translations}
              <FlagsListCategory
                active={category.id_language}
                category={category.id}
                {clickOnFlag}
                languages={category.translations.map(el => el.id_language)}
                missingTranslations={category.missingTranslations}
              />
            {/if}
            {#if category.original}
              <Flag
                id={category.original.id_language}
                active={category.id_language === category.original.id_language}
                clickOnFlag={(lang) => clickOnFlag(lang, category.id, 0)}
                variant='small'
              />
            {/if}
          </div>
        </div>
      {/if}
    </div>
  {/each}
</div>

<style lang='scss'>
  .tabs-and-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .category-heading {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    justify-content: space-between;
    width: 70%;
  }

  .title-and-description {
    > small {
      line-height: 1.4;
      color: var(--text-primary-color);
    }

    > .subCategoryTitle {
      margin: 0;
      font-weight: bold;
      line-height: 1.2;
    }
  }

  .topics-number {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .parent-category {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }

  .group-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.6rem;
  }

  .group-category-title {
    display: flex;
    gap: 2.4rem;
    align-items: center;

    > span {
      font-weight: bold;

    }

    > small {
      font-size: 1.2rem;
    }
  }

  .single-category {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 4.8rem;
    cursor: pointer;

    &:hover {
      .title-and-description {
        text-decoration: underline;
      }
    }

    &.-subcategory {
      padding-left: 2.4rem;
    }
  }

  .selected-languages {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    justify-content: end;
    min-width: 25.6rem;

    > .verticalLine {
      height: 2.4rem;
      border-right: 0.1rem solid var(--Gray-Light);
    }

    &.-top {
      display: flex;
      flex-direction: row;

      > :global(div:first-child) {
        display: flex;
      }
    }
  }

  .topics-number {
    margin: 0 1rem;

    > .numberTopicTitle {
      font-size: 1rem;
      text-transform: lowercase;
      color: var(--text-primary-color);
    }

    > .totalTopicNumber {
      font-size: 1.4rem;
    }
  }

  @media (max-width: 768px) {
    .by-category {
      padding: 0;
      background-color: inherit;
      border: none;
      border-bottom: 0.2rem solid var(--Gray-Med);
      border-radius: 0;
    }

    .tabs-and-filters {
      display: none;
    }

    .single-category {
      &.-subcategory {
        flex-direction: column-reverse;
        gap: 0.8rem;
        align-items: start;
        padding-block: 0.4rem;
        padding-left: 1.6rem;
      }
    }

    .selected-languages {
      display: flex;
      align-self: center;
      justify-content: flex-end;
      min-width: unset;
    }

    .category-heading {
      justify-content: space-between;
      width: 100%;
    }

    .group-wrapper {
      flex-direction: column-reverse;
      gap: 0;
      align-items: start;
    }

    .group-category-title {
      flex-direction: column;
      gap: 0;
      align-items: start;
    }
  }
</style>
