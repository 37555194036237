<script lang='ts'>
  import { link } from 'svelte-routing'

  import { MIN_REQUIRED_TEXT_LENGTH } from '@/config/constants'

  import { emojiAutocomplete } from '@/helpers/emojiHelpers'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { notifier } from '@/helpers/notifier'

  import { EditForumTopic, QuillOptions, QuillType, QuillWrapper } from '@/definitions/langoid'

  let quill: QuillType

  export let language = 0,
    topicId = 0,
    replyId = 0

  const options: QuillOptions = {}
  const TIME_FOR_DISPLAYING_TEXT = 500
  let topicData: EditForumTopic = {} as EditForumTopic
  let title = ''
  let content = {
      html: '',
      text: ''
    },
    editSent = false
  if (replyId) {
    import('@/helpers/svelteQuill').then((el: QuillWrapper) => {
      quill = el.quill
    })
  }
  fetchData('forum/editLoad', { language, replyId, topicId }).then(data => {
    topicData = data
    title = topicData.content || ''
    if (replyId) {
      setTimeout(() => {
        const editorElement = document.querySelector('.ql-editor')
        if (editorElement) {
          editorElement.innerHTML = topicData.content
        }
      }, TIME_FOR_DISPLAYING_TEXT)
    }
  })

  async function saveEdit () {
    if (!replyId && title.length <= MIN_REQUIRED_TEXT_LENGTH) {
      notifier.error($_('forum.titleTooShort'))
      return
    }
    if (replyId && content.text.length <= MIN_REQUIRED_TEXT_LENGTH) {
      notifier.error($_('forum.contentTooShort'))
      return
    }

    await fetchData('forum/editSave', { content: replyId ? content.html : title, language, replyId, topicId })
    editSent = true
  }

  const emptyString = ''
  const setContent = (e: CustomEvent) => {
    content = e.detail
  }
</script>
<div class='forum-edit-wrapper _gap24'>
  {#if editSent}
    Edited <a
    href='/forum/{topicData.id_category}/{topicId}{replyId ? `?reply=${replyId}` : emptyString}'
    use:link
  >{$_('forum.goBack')}</a>
  {:else}
    <form on:submit|preventDefault={saveEdit}>
      {#if !replyId}
        Topic <input
        class='editForum'
        required
        type='text'
        bind:value={title}
        use:emojiAutocomplete
      />
      {:else if quill}
        <div class='editor' on:text-change={setContent} use:quill={options} />
      {/if}
      <input type='submit' value={$_('mix.save')} />
    </form>
  {/if}
</div>
<style lang='scss'>
  .forum-edit-wrapper {
    > form > .editForum {
      width: 100%;
    }
  }
</style>
